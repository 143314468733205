import { useState } from "react";
// import { ChatNavigation } from "../shared/Nav";
import {
  Avatar,
  Button,
  Textarea,
  mergeClasses,
} from "@fluentui/react-components";
import { Send24Regular } from "@fluentui/react-icons";
import { Chat, ChatMessage, ChatMyMessage } from "@fluentui-contrib/react-chat";
import { useChatStyles } from "./chat.styles";

export default function IsmgChat(props: any) {
  const examples = [
    "How to write an article",
    "How to write a blog post on cybersecurity",
    "How Keywords in article helps",
    "How many layers are there in osi model",
    "How can i attend cybersecurity events",
    "How to write a story",
  ];

  const [chat, setChat] = useState([]) as any[];
  const [input, setInput] = useState("");
  const [chatHistory, setChatHistoy] = useState([]) as any[];
  const [title, setTitle] = useState("");

  const handleSend = async () => {
    if (input.trim() === "") {
      return;
    }
    setChat([...chat, { role: "user", content: input }]);
    setInput("");
    const response = await fetch(
      `http://localhost:8080/api/v1/chat?ssoToken=${props.token}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: props.email,
          messages: [...chat, { role: "user", content: input }],
        }),
      }
    );
    const readData = response.body
      ?.pipeThrough(new TextDecoderStream())
      .getReader();
    let aiRes = "";
    while (true) {
      //@ts-ignore
      const { done, value } = await readData?.read();
      if (done) {
        break;
      }
      aiRes += value;
      setChat([
        ...chat,
        { role: "user", content: input },
        { role: "assistant", content: aiRes },
      ]);
    }

    if (!title) {
      const createTitle = await fetch(
        `http://localhost:8080/api/v1/history-title?ssoToken=${props.token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: props.email,
            title: input,
          }),
        }
      );

      const data_title = await createTitle.json();
      setTitle(data_title.title);
      setChatHistoy([...chatHistory, data_title]);
    }
  };

  const styles = useChatStyles();
  return (
    <div
      className={mergeClasses("ms-Grid", styles.chatContainerBackground)}
      dir="ltr"
    >
      <div className="ms-Grid-row">
        {/* Navigation  */}

        <div
          className={mergeClasses(
            "ms-Grid-col",
            "ms-sm2 ms-xl2",
            "!min-h-full",
            "!pt-6",
            styles.chatHistoryBg
          )}
        >
          <Button
            appearance="primary"
            onClick={() => {
              setChat([]);
              setTitle("");
            }}
            className="w-full"
          >
            Reset Chat
          </Button>
          {/* <ChatNavigation history={chatHistory} /> */}
        </div>

        {/* Chat  */}
        <div className="ms-Grid-col ms-sm10 ms-xl10">
          {chat.length > 0 ? (
            <Chat className="chat-box-wrapper !overflow-y-scroll !no-scrollbar">
              {chat.map((item: any, index: any) => (
                <div>
                  {item.role === "assistant" ? (
                    <ChatMessage
                      avatar={
                        <Avatar
                          name="Artificial Intelligence"
                          badge={{ status: "available" }}
                        />
                      }
                    >
                      {item.content}
                    </ChatMessage>
                  ) : (
                    <ChatMyMessage status="received">
                      {item.content}
                    </ChatMyMessage>
                  )}
                </div>
              ))}
            </Chat>
          ) : (
            <div className="app-showcase">
              <div className="mb-12">
                <h2 className={styles.titleColor}>
                  ISMG
                  <span
                    className={mergeClasses("text-sm ml-1", styles.helpText)}
                  >
                    chatGpt
                  </span>
                </h2>
                <span
                  className={mergeClasses("text-xs", "mt-12", styles.helpText)}
                >
                  Powered by openAi Gpt 4
                </span>
              </div>

              <div className="chat-examples gap-6">
                {examples.map((item, index) => (
                  <Button
                    appearance="primary"
                    className="!min-w-[400px] "
                    key={index}
                    onClick={() => setInput(item)}
                  >
                    {item}
                  </Button>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="ms-Grid-col ms-sm10 ms-xl10 prompt-box-wrapper mt-6">
          <div className="prompt-box relative pb-5">
            <Textarea
              size="small"
              resize="none"
              value={input}
              placeholder="Type your prompt..."
              className={styles.promptInput}
              onChange={(e) => setInput(e.target.value)}
              onKeyUp={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault(); // Prevent the default action to avoid a newline in textarea
                  if (input.trim()) {
                    handleSend();
                  }
                }
              }}
            />
            <div className="flex justify-between w-full mt-2">
              <div></div>
              <Send24Regular
                className="ml-3 cursor-pointer"
                onClick={() => (input.trim() ? handleSend() : undefined)}
              />
            </div>

            <div>
              <small className={styles.infoText}>
                Ai can generate incorrect information.
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
